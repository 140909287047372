<template>
  <div class="container">
      <el-form label-width="120px" ref="form">
       
        <el-form-item label="到店学员" required>
        <div class="flex flex-wrap">
            <div v-if="stuList.length == 0">
                请填写下方的学员信息
            </div>
            <div class="stu-item flex al-c" v-for="(stu,i) in stuList" :key="i">
                <div>
                    <div class="stu-name">{{stu.name}}</div>
                    <div class="stu-phone">{{stu.phone}}</div>
                </div>
                <i class="el-icon-delete red" @click="delItem(i)"></i>
            </div>
        </div>
        </el-form-item>

      
        <div class="bg">
        <el-form-item label="平台账号">
          <user-select ref="user" @change="userChange" :userid.sync="user_id"></user-select>
          <p>
            若用户未注册可不选
          </p>
        </el-form-item>
        <el-form-item label="姓名" required>
          <el-input v-model="stuForm.name" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="电话" required>
        <el-input v-model="stuForm.phone" placeholder="电话"></el-input>
        </el-form-item>
        <el-form-item label="" >
        <el-button  type="primary"  @click="addStudent"
          >确认</el-button
        >
        </el-form-item>
        
        </div>

        <el-form-item label="到店凭证" required>
          <images-uploader
                @getImgUrl="(v) => (form.pic = v)"
              >
              </images-uploader>
        </el-form-item>
        <el-form-item label="说明" required>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="form.remark">
            </el-input>
        </el-form-item>
      </el-form>
      <span>
        <el-button @click="back">取 消</el-button>
        <el-button  type="primary" :loading="loading" @click="addForm"
          >新 增</el-button
        >
      </span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      stuList:[],
      stuForm:{
        name:'',
        phone:''
      },
      user_id:'',
      loading:false,
      mechanism_id:'',
      managerInfo:JSON.parse(localStorage.getItem('managerInfo'))
      
    };
  },

  mounted() {
     this.mechanism_id = this.$route.query.mid
  },

  methods: {
    delItem(i){
        this.stuList.splice(i,1)
    },
    addStudent(){
        if(!this.stuForm.name){
            this.$message('请填写姓名')
            return
        }
        if(!this.stuForm.phone){
            this.$message('请填写电话')
            return
        }

        let stu = {
            user_id:this.user_id,
            name:this.stuForm.name,
            phone:this.stuForm.phone
        }
        this.$refs.user.clear()
        this.user_id = ''
        this.stuForm={name:'',phone:''}
        this.stuList.push(stu)
    },
    userChange(v){
        this.stuForm.name = v.nick_name
        this.stuForm.phone = v.login_name
    },
    back(){
        this.$router.back()
    },
   
    addForm() {
      if (!this.form.pic) {
        this.$message("请上传到店图片");
        return;
      }
      if (!this.form.remark) {
        this.$message("请填写说明");
        return;
      }
      if (this.stuList.length == 0) {
        this.$message("请填写学员");
        return;
      }

      
      let data = {
            ...this.form,
            mechanism_id:this.mechanism_id,
            students:this.stuList,
            handler_id:this.managerInfo.id,
            handler_name:this.managerInfo.name
      }

      this.loading = true;
      this.$axios({
        url: "/user/userArrivalRecord/insertBatch",
        method: "post",
        data: data,
      })
        .then((res) => {
          this.$notify({
            message: "新增成功",
            duration: 2000,
            type: "success",
          });
          this.loading = false;
          this.back()
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
};
</script>

<style lang="less" scoped>
.bg{
    padding: 10px 0;
    background-color: #f4f4f4;
}

.stu-item{
    padding: 10px;
    line-height: 1.5;
    background-color: rgb(215, 250, 250);
    border-radius: 4px;
    width: fit-content;
    margin: 0 10px 10px 0;
}

.stu-name{
    font-size: 16px;
}

.stu-phone{
 font-size: 13px;
 color: #9c9c9c;
}
.red{
    color: #eb4747;
    margin-left: 8px;
}
</style>